@import '../../../styles/combined_mixins.scss';
@import '../../../styles/combined_styles.scss';

.logo-panel {
    background-color: $accentPrimary;
    height: 4rem;

    .logo {
        padding: 1rem $x-large-REM;
        font-size: $x-large-REM;
        @include font-weight-semi-bold-mixin;

        .logo-text1 {
            @include font-weight-bold-mixin;
            color: $logoFontColor1;
        }
        .logo-text2 {
            color: $logoFontColor2;
        }
        .logo-text3 {
            @include font-weight-normal-mixin;
            font-style: italic;
            color: $logoFontColor2;
        }
    }
}