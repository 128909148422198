@import '../../styles/combined_mixins';
@import '../../styles/combined_styles';

.locationMapPin {
  position: relative;

  .icon {
    color: $accentSecondary;
    font-size: 2rem;
    position: absolute;
    top: -2rem;
    left: -0.7rem;
  }

  .label {
    font-size: 1rem;
    background-color: $lightest;
    border: 1px solid $darker;
    border-radius: 5px;
    padding: $x-tiny-REM;
    width: fit-content;
    white-space: nowrap;
    position: absolute;
    transform: translate(-50%, calc(-100% - 2.15rem));
    /*top: -4rem;
    left: 1rem;*/
  }
}
