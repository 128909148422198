@import '../../styles/combined_mixins';
@import '../../styles/combined_styles';

.page_container {
  height: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .feature_editor {
    background-color: $formBackground;
    width: 100%;
    padding: $medium-REM;
    border: 1px solid $darker;
    border-radius: $tiny-REM;
    overflow: auto;

    .textInput, .selectInput {
      width: 100%;
    }
  }

  .feature_editor_buttons {
    padding: $medium-REM;
    margin-bottom: $xx-small-REM;

    & button:first-child {
      margin-right: $medium-REM;
    }
  }
}