@import '../../../styles/combined_mixins.scss';
@import '../../../styles/combined_styles.scss';

.app-menu {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
        font-size: $medium-REM;

        li {
            text-align: left;
            padding: $large-REM;
            color: $leftNavBarForeground;
            border-left: $tiny-REM solid transparent;

            .menu-item-icon {
                @include font-weight-thin-mixin;
            }

            span.menu-item-name {
                text-transform: capitalize;
                padding-left: $medium-REM;
                @include font-weight-semi-bold-mixin;

                .menu-item-edit {
                    color: $menuItemEditIcon;
                }
            }
        }

        li:hover:not(.app-menu-item-selected) {
            background-color: $menuItemHoverBackground;
            border-left: $tiny-REM solid $accentPrimary;
        }

        .app-menu-item-selected {
            background-color: $menuItemSelectedBackground;
            border-left: $tiny-REM solid $accentPrimary;
        }
    }
}