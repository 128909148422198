@import '../variables/colors';

@mixin disabled-mixin {
    @include unclickable-mixin;
    border: none;
    background-color: $disabledButtonBackground;
    color: $disabledButtonForeground;
}

@mixin unclickable-mixin {
    pointer-events: none;
}