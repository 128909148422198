@import '../../styles/combined_mixins';
@import '../../styles/combined_styles';

/* Local Variables */
$photoPanelHeight: 35rem;
$dropZoneCursor: pointer;

.filepond--root {
  font-family: $font1;
  font-size: 1.5rem;
  margin-bottom: 0;
  cursor: $dropZoneCursor;

  .filepond--file {
    cursor: auto;
  }
}

.filepond--wrapper {
  margin: $medium-REM;
}

.filepond--panel-root {
  border-radius: $tiny-REM;
  background-color: $formBackground;
}

.filepond--drop-label {
  border: 2px dashed $dark;
  border-radius: $tiny-REM;
  cursor: $dropZoneCursor;

  & label {
    cursor: $dropZoneCursor;
  }

  &:hover {
    border: 2px dashed $darker;
    background-color: $dark;
  }
}

.generic_link {
  color: $accentPrimary;

  &:hover {
    text-decoration: underline;
  }
}

.photoPage_container {
  background-color: $formBackground;
  border: 1px solid $darker;
  border-radius: $tiny-REM;
  width: 100%;

  .property_photo_scroller {
    position: relative;
    min-height: $photoPanelHeight;

    .property_photos {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: space-between;
      margin-top: $medium-REM;

      .photo_frame {
        position: relative;
        display: flex;
        flex: 0 0 calc(20% - $small-REM);
        justify-content: center;
        align-items: flex-end;
        aspect-ratio: 1/1;
        margin: $tiny-REM;
        height: 10rem;
        background-color: $darker;
        border: 1px solid $dark;
        opacity: 0.9;

        &:hover {
          border: 1px solid $accentPrimary;
          opacity: 1;
        }

        .photo_controls {
          position: absolute;
          display: flex;
          justify-content: center;
          z-index: 200;
          font-size: $x-large-REM;
          width: 100%;
          background-color: $darker;
          color: $lightest;

          .photo_control {
            margin: 0 0.125rem;

            &.feature-enabled {
              color: $accentTertiary;

              &:hover {
                cursor: auto;
              }
            }

            &:hover {
              cursor: pointer;
            }

            &:hover:not(.feature-enabled) {
              color: $white;
            }
          }
        }

        .photo_mask {
          position: relative;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(to bottom, $buttonTertiaryBackground 80%, $white 100%);

          img {
            padding: $tiny-REM;
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
      }

      .no_photos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 3rem;
        height: calc($photoPanelHeight - 3rem);
        color: $darker;
      }
    }
  }
}

.property_photo_editor_buttons {
  padding: $medium-REM;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: $xx-large-REM;
}
