@import '../../styles/combined_mixins';
@import '../../styles/combined_styles';

.base_header {
  .base_filters {
    .filters_label {
      margin: 0 $xx-small-REM 0 0;
      font-weight: bold;
      color: $fontColor1;
    }

    .filters_buttongroup {
      background-color: $buttonSecondaryBackground;
      padding: $x-tiny-REM;

      & .MuiButton-root {
        border: 0;
      }
    }

    .filter_selected {
      color: $buttonPrimaryForeground;
      background-color: $buttonPrimaryBackground;

      &:focus, &:hover {
        background-color: $buttonPrimaryBackground;
      }
    }

    .filter_unselected {
      &:focus, &:hover {
        background-color: $light;
      }
    }
  }
}
