@import '../../styles/combined_mixins.scss';
@import '../../styles/combined_styles.scss';

.top-bar {
    height: 4rem;
    background-color: $topBarBackground;
    position: fixed;
    top: 0;
    right: 0;
    left: 14.25rem;
    z-index: 995;

    .top-bar-content {
        padding: 1rem $x-large-REM;
        display: flex;
    }
}