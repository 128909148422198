@import '../../styles/combined_mixins';
@import '../../styles/combined_styles';

.page_container {
  height: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .viewing_editor {
    background-color: $formBackground;
    width: 100%;
    padding: $medium-REM;
    border: 1px solid $darker;
    border-radius: $tiny-REM;
    overflow: auto;

    .textInput, .selectInput, .datePicker {
      width: 100%;
    }

    .datePicker {
      background-color: $textInputBackground;
      border-top-left-radius: $x-tiny-REM;
      border-top-right-radius: $x-tiny-REM;

      &:hover {
        background-color: $dark;
      }

      & label {
        top: $medium-PX;
      }

      & input {
        position: relative;
        top: $x-small-PX;
        cursor: pointer;
      }

      & fieldset {
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0;
      }
    }
  }

  .viewing_editor_buttons {
    padding: $medium-REM;
    margin-bottom: $xx-small-REM;

    & button:first-child {
      margin-right: $medium-REM;
    }
  }
}