/**************************************************/
/** ROOT ELEMENT SIZING                          **/
/**************************************************/
$x-tiny-REM:    0.25rem;
$tiny-REM:      0.375rem;
$xx-small-REM:  0.563rem;
$x-small-REM:   0.625rem;
$small-REM:     0.813rem;
$medium-REM:    1rem;
$large-REM:     1.125rem;
$x-large-REM:   1.5rem;
$xx-large-REM:  2rem;
$xxx-large-REM: 3.5rem;

/**************************************************/
/** PIXEL SIZING                                 **/
/**************************************************/
$x-tiny-PX:     4px;
$tiny-PX:       6px;
$xx-small-PX:   9px;
$x-small-PX:    10px;
$small-PX:      13px;
$medium-PX:     16px;
$large-PX:      18px;
$x-large-PX:    24px;
$xx-large-PX:   32px;
$xxx-large-PX:  46px;

/**************************************************/
/** FONT WEIGHTS                                 **/
/**************************************************/
$font-weight-thin:      100;
$font-weight-normal:    400;
$font-weight-semi-bold: 600;
$font-weight-bold:      700;
$font-weight-thick:     900;

/**************************************************/
/** SHADOWS                                      **/
/**************************************************/
$outline-glow: 0 0 8px;
$outline-shadow: 2px 2px 5px;
$inset-shadow-large: inset $x-small-REM $x-small-REM $x-small-REM -8px;