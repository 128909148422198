@import '../../styles/combined_mixins';
@import '../../styles/combined_styles';

.property-status-label {
    min-width: $xxx-large-REM;
}
.base_header {
    .base_filters {
        .property_filters_label {
            margin: 0 $xx-small-REM 0 0;
            font-weight: bold;
            color: $fontColor1;
        }

        .property_filters_buttongroup {
            background-color: $buttonSecondaryBackground;
            padding: $x-tiny-REM;

            & .MuiButton-root {
                border: 0;
            }
        }

        .featured_filter_selected {
            color: $buttonPrimaryForeground;
            background-color: $buttonPrimaryBackground;

            &:focus, &:hover {
                background-color: $buttonPrimaryBackground;
            }
        }

        .featured_filter_unselected {
            &:focus, &:hover {
                background-color: $light;
            }
        }
    }
}

.new_property_menu {
    padding: $xx-small-REM $small-REM;
    cursor: pointer;

    &:hover {
        background-color: $dark;
    }
}

.featured_icon, .featured_icon_static {
    color: $buttonTertiaryForeground;
    border-radius: $x-tiny-REM;
    height: $small-REM;
    padding: $x-tiny-REM 0;
    min-width: $medium-REM;
}

.featured_icon {
    cursor: help;
    background-color: $dark;
    &.active {
        background-color: $accentSecondary;
    }
}

.featured_icon_static {
    background-color: $accentSecondary;
}