@import '../../styles/combined_mixins';
@import '../../styles/combined_styles';

.search-result {
  height: 95%;
  background-color: $formBackground;
  width: 100%;
  padding: $medium-REM;
  border: 1px solid $darker;
  border-radius: $tiny-REM;
  overflow: auto;

  .search-result-wrapper {
    display: flex;
    margin: 0 0 2rem 0;
    width: 80%;

    .search-result-link {
      display: flex;
      flex-grow: 0;
      flex-direction: column;

      .search-result-title {
        color: $link;
        font-size: 1.5rem;
        white-space: pre-wrap;
        place-self: flex-start;

        .highlight {
          background-color: $textHighlight;
          display: unset;
        }

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .search-result-description {
        white-space: pre-wrap;

        .highlight {
          background-color: rgb(240 237 88 / 48%);
          display: inline-flex;
        }

        .link {
          cursor: pointer;
          font-style: italic;

          &:hover {
            color: $link;
            text-decoration: underline;
          }
        }

        .supplemental {
          color: $darker;
        }
      }
    }
  }
}