@import '../../styles/combined_mixins';
@import '../../styles/combined_styles';

.selectOne_contact_menu_item {
  .label {
    display: inline;
    margin-right: 1rem;
  }
  .subLabel {
    font-size: $small-REM;
    font-style: italic;
    color: $darker;
    &:not(:last-child):after {
      font-weight: bold;
      color: $darker;
      content: ' • ';
    }
  }
}

.page_container {
  height: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .property_editor {
    background-color: $formBackground;
    width: 100%;
    padding: $medium-REM;
    border: 1px solid $darker;
    border-radius: $tiny-REM;
    overflow: auto;

    .featured_input {
      margin: 0;
      padding: 0 0 $medium-REM;
    }

    /* TODO: MOVE TO INDIVIDUAL COMPONENT */
    .multiselect_checkbox_menu_selection_value {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .compositeWrapper {
      display: flex;
      justify-content: space-between;

      .textInput.composite2, .selectInput.composite2 {
        width: 47.5%;
      }

      .textInput.composite3, .selectInput.composite3 {
        width: 30%;
      }
    }

    .textInput.compositeVertical:not(:last-child) {
      margin-bottom: $x-large-REM;
    }

    .textInput, .selectInput {
      width: 100%;

      .map_edit_icon {
        font-size: $x-large-REM;
        color: $darker;
      }
    }
  }

  .property_map {
    height: 100%;
    min-height: 15rem;
    width: 100%;
    border: 1px solid $darker;
    position: relative;

    &.dashed_border {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px dashed $darker;
      cursor: pointer;
      opacity: 0.4;

      .empty_state {
        width: 50%;
        text-align: center;

        .instructions {
          color: $charcoal;
          font-size: 1.5rem;
        }

        .icon {
          color: $charcoal;
          font-size: 5rem;
          margin-bottom: 1rem;
        }
      }

      &:hover {
        opacity: 1;

        .icon {
          color: $magicPotion;
        }
      }
    }

    .hide-map-button {
      position: absolute;
      z-index: 1;
      opacity: 0.5;
      margin: $x-small-REM;

      &:hover, &:active {
        opacity: 1;
      }
    }
  }

  .property_editor_buttons {
    padding: $medium-REM;
    margin-bottom: $xx-small-REM;

    & button:first-child {
      margin-right: $medium-REM;
    }
  }
}

