@import '../../styles/combined_mixins';
@import '../../styles/combined_styles';

.loadingStateWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 8rem);

  .loadingStateContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 40%;

    .spinnerWrapper {

      .spinner {
        color: $darker;
        font-size: 3rem;
        text-align: center;
      }
    }

    .text {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: row;

      font-size: 2.5rem;
      font-weight: normal;

      margin-top: 0;

      .highlight {
        color: $accentTertiary;
        font-weight: bold;
      }
   }
  }
}