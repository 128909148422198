@import '../../styles/combined_mixins';
@import '../../styles/combined_styles';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 8rem);

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 40%;

    .iconWrapper {
      padding: 3.5rem;
      background-color: $dark;
      border-radius: 50%;
      opacity: 0.5;

      .icon {
        color: $darker;
        font-size: 6rem;
        text-align: center;
      }
    }

    .text {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;

      font-size: 2.5rem;
      font-weight: bold;
      color: $darker;

      margin-top: 1rem;

      .subtext {
        font-size: 2rem;
        font-weight: normal;
        margin: 1.5rem 0;
      }
    }
  }
}