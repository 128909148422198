@import '../../styles/combined_mixins';
@import '../../styles/combined_styles';

.data-table {
  & .MuiDataGrid-columnHeader, & .MuiDataGrid-cell, & .MuiDataGrid-cellCheckbox {
    outline: none !important;
  }

  & .MuiTouchRipple-root {
    color: $buttonTertiaryForeground;
  }

  .MuiDataGrid-sortIcon {
    color: $buttonTertiaryForeground;
  }
}