@import '../../styles/combined_mixins.scss';
@import '../../styles/combined_styles.scss';

.search-form {
    display: inline-flex;

    .search-input-wrapper {
        position: relative;
        width: 40rem;

        .search-input {
            color: $topBarSearchInputTextColor;
            background-color: $topBarSearchInput;
            border: none;
            outline: none;
            padding: $x-tiny-REM $large-REM $x-tiny-REM $xx-small-REM;
            box-sizing: border-box;
        }

        & ::placeholder {
            color: $topBarSearchInputPlaceholder;
        }

        button.clear-search-button {
            color: $darker;
            padding: 0;
            margin: 0;
            position: absolute;
            right: 0;
            font-size: $small-REM;
            min-width: $x-large-REM;
        }
    }
}