@import '../variables/dimensions';

@mixin header-font-mixin {
    font-size: $xx-large-REM;
    font-weight: $font-weight-semi-bold;
    padding: $x-small-REM $xx-large-REM;
}

@mixin font-weight-thin-mixin {
    font-weight: $font-weight-thin;
}

@mixin font-weight-normal-mixin {
    font-weight: $font-weight-normal;
}

@mixin font-weight-semi-bold-mixin {
    font-weight: $font-weight-semi-bold;
}

@mixin font-weight-bold-mixin {
    font-weight: $font-weight-bold;
}

@mixin font-weight-thick-mixin {
    font-weight: $font-weight-thick;
}