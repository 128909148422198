@import '../../styles/combined_mixins.scss';
@import '../../styles/combined_styles.scss';

.button {
    white-space: nowrap;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),
                0px 2px 2px 0px rgba(0,0,0,0.14),
                0px 1px 5px 0px rgba(0,0,0,0.12);

    &:focus, &:hover {
        box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),
                    0px 4px 5px 0px rgba(0,0,0,0.14),
                    0px 1px 10px 0px rgba(0,0,0,0.12);
    }

    &.generic:not(.button-shadow),   &.primary:not(.button-shadow),
    &.secondary:not(.button-shadow), &.icon:not(.button-shadow),
    &.custom:not(.button-shadow) {
        &.MuiButton-root:not(.button-shadow) {
            box-shadow: unset;
        }
    }

    &.generic {
        color: $buttonGenericForeground;
        background-color: $buttonGenericBackground;

        &:focus, &:hover {
            background-color: $buttonGenericBackground;
        }
    }

    &.primary {
        color: $buttonPrimaryForeground;
        background-color: $buttonPrimaryBackground;
        @include font-weight-bold-mixin;

        &.MuiButton-root {
            border: 1px solid $dark;
            padding: 5px 16px;
        }

        &:focus, &:hover {
            background-color: $buttonPrimaryBackground;
            border: 1px solid $buttonPrimaryBorderHover;
        }
    }

    &.secondary {
        color: $buttonSecondaryForeground;
        background-color: $buttonSecondaryBackground;
        border: 1px solid $light;
        text-transform: none;

        &:focus, &:hover {
            background-color: $buttonSecondaryBackground;
            border: 1px solid $lighter;
        }
    }

    &.icon {
        border: none;
        outline: none;
        background: none;
        box-shadow: unset;
        color: $buttonSecondaryBackground;
    }

    &.custom {
        color: $buttonTertiaryForeground;
        background-color: $buttonTertiaryBackground;

        &:focus, &:hover {
            background-color: $buttonTertiaryBorderHover;
        }
    }

    &:focus-visible {
        outline: none;
    }
}