@import 'combined_mixins.scss';
@import 'combined_styles.scss';
@tailwind base;
@tailwind utilities;

html, body {
    margin: 0;
    padding: 0;
    font-family: $font1;
    font-size: $medium-REM;
    @include font-weight-normal-mixin;
}

.user-menu-anchor {
    cursor: pointer;
    display: inline-flex;

    .user-menu-name {
        white-space: nowrap;
        margin: 0 $x-tiny-REM;
        font-weight: 600;
    }
}

.user_menu .MuiPaper-elevation {
    margin-top: $x-large-REM;
    cursor: pointer;

    .popover_menu_item {
        padding: $xx-small-REM $xx-small-REM $xx-small-REM $medium-REM;
        font-size: $large-REM;
        font-weight: 500;
        color: $pageTitle;
        margin: 0;

        &:hover {
            background-color: $menuHoverBackground;
        }

        &.separator {
            border-top: 1px solid $separator;
        }

        .label {
            margin-left: $medium-REM;
        }
    }
}

.app-root {
    .loading-spinner {
        height: 100vh;
    }
}

.app-body {
    background-color: $bodyBackground;
    position: fixed;
    top: 4rem;
    left: 14.25rem;
    box-shadow: $inset-shadow-large $leftNavBarShadow;
    height: 100%;
    width: calc(100% - 14.2rem);

    .page-header {
        padding: $x-large-REM $x-large-REM 0 $x-large-REM;

        .page-title-wrapper {
            display: flex;
            color: $pageTitle;
            font-size: 2rem;
            text-transform: capitalize;
            padding-bottom: 1rem;

            .page-title {
                display: inline;
                margin-left: 1rem;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 70%;
            }
        }
    }

    .page-body {
        padding: 0 $x-large-REM 0 $x-large-REM;

        .page_container {
            .MuiGrid-item {
                padding-top: 23px;
            }
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 9999s ease-in-out 0s;
}

::selection {
    background: $textSelectionColor;
}

::-moz-selection {
    background: $textSelectionColor;
}

.spinner {
    animation: spin infinite 2s linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.datagrid-action-icon {
    font-size: 1.1rem;
}

.datagrid-action-menu-icon {
    font-size: 1.3rem;
}

.base_header {
    display: flex;
    justify-content: space-between;

    .base_filters {
        display: flex;
        align-items: center;
    }
}