@import '../font/font_mixins';
@import url('https://fonts.googleapis.com/css2?\
family=Courgette&\
family=Damion&\
family=Exo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&\
family=Source+Serif+Pro:wght@600&display=swap'
);

$font1: 'Exo', sans-serif;
$font2: 'Damion', sans-serif;
$font3: 'Courgette', sans-serif;
$font4: 'Source Serif Pro', sans-serif;

.font-weight-thin {
    @include font-weight-thin-mixin;
}
.font-weight-normal {
    @include font-weight-normal-mixin;
}
.font-weight-semi-bold {
    @include font-weight-semi-bold-mixin;
}
.font-weight-bold {
    @include font-weight-bold-mixin;
}
.font-weight-thick {
    @include font-weight-thick-mixin;
}
