@import '../../styles/combined_mixins';
@import '../../styles/combined_styles';

/* Local Variables */
$videoPanelHeight: 35rem;
$dropZoneCursor: pointer;

.filepond--root {
  font-family: $font1;
  font-size: 1.5rem;
  margin-bottom: 0;
  cursor: $dropZoneCursor;

  .filepond--file {
    cursor: auto;
  }
}

.filepond--wrapper {
  margin: $medium-REM;
}

.filepond--panel-root {
  border-radius: $tiny-REM;
  background-color: $formBackground;
}

.filepond--drop-label {
  border: 2px dashed $dark;
  border-radius: $tiny-REM;
  cursor: $dropZoneCursor;

  & label {
    cursor: $dropZoneCursor;
  }

  &:hover {
    border: 2px dashed $darker;
    background-color: $dark;
  }
}

.generic_link {
  color: $accentPrimary;

  &:hover {
    text-decoration: underline;
  }
}

.videoPage_container {
  background-color: $formBackground;
  border: 1px solid $darker;
  border-radius: $tiny-REM;
  width: 100%;

  .property_video_scroller {
    position: relative;
    min-height: $videoPanelHeight;

    .property_videos {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: space-between;
      margin-top: $medium-REM;

      .video_frame {
        position: relative;
        display: flex;
        flex: 0 0 calc(80% - $small-REM);
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-end;
        aspect-ratio: 1/1;
        margin: $tiny-REM;
        height: 30rem;
        border: 1px solid $dark;
        opacity: 0.9;

        &:hover {
          border: 1px solid $accentPrimary;
          opacity: 1;
        }

        .video_mask {
          position: relative;
          width: 100%;
          height: 100%;
          margin-bottom: $small-REM;
          background-image: linear-gradient(to bottom, $buttonTertiaryBackground 80%, $white 100%);

          video {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
      }

      .no_videos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 3rem;
        height: calc($videoPanelHeight - 3rem);
        color: $darker;
      }
    }
  }
}

.property_video_editor_buttons {
  padding: $medium-REM;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: $xx-large-REM;
}
