// https://www.color-name.com/hex/[hex value]

/*******************************
** COLOR DEFINITIONS
*******************************/
$white: #FFFFFF;
$antiFlashWhite: #F0F0F0;
$ghostWhite: #F9FAFB;
$cultured: #F5F6FA;
$brightGray: #E4E9EE;
$lightPeriwinkle: #BFCBDA;
$romanSilver: #7D858F;
$moonstoneBlue: #7ca8c5;
$pictonBlue: #48B0F7;
$pictonBlueFaded: rgba(72, 176, 247, 0.40);
$newCar: #2455c7;
$royalOrange: #F78F48;
$mediumVermilion: #d3552f;
$inchworm: #b2f058;
$mangoGreen: #97ff00;
$greenRyb: #7eaf2c;
$magicPotion: #F7484D;
$deepSpaceSparkle: #496476;
$outerSpace: #3f4a59;
$charcoal: #394250;
$black: #000000;
$highlighterYellow: rgb(240 237 88 / 48%);
$light: rgba(255, 255, 255, 0.1);
$lighter: rgba(255, 255, 255, 0.4);
$lightest: rgba(255, 255, 255, 0.8);
$dark: rgba(0, 0, 0, 0.1);
$darker: rgba(0, 0, 0, 0.4);
$darkest: rgba(0, 0, 0, 0.8);


/*******************************
** FONTS
*******************************/
$fontColor1: $romanSilver;
$logoFontColor1: $mediumVermilion;
$logoFontColor2: $black;


/*******************************
** BUTTONS
*******************************/
$buttonBorder: $lightPeriwinkle;
$buttonGenericBorderHover: $pictonBlueFaded;
$buttonGenericBackground: $ghostWhite;
$buttonGenericForeground: $outerSpace;
$buttonPrimaryBorderHover: $greenRyb;
$buttonPrimaryBackground: $inchworm;
$buttonPrimaryForeground: $outerSpace;
$buttonSecondaryBorderHover: $charcoal;
$buttonSecondaryBackground: $charcoal;
$buttonSecondaryForeground: $lightPeriwinkle;
$buttonTertiaryBorderHover: $outerSpace;
$buttonTertiaryBackground: $outerSpace;
$buttonTertiaryForeground: $white;


/*******************************
** NAV BAR
*******************************/
$leftNavBarBackground: $outerSpace;
$leftNavBarShadow: $lightPeriwinkle;
$leftNavBarForeground: $white;
$menuItemHoverBackground: $charcoal;
$menuItemSelectedBackground: $deepSpaceSparkle;
$menuItemEditIcon: $romanSilver;


/*******************************
** TOP BAR
*******************************/
$topBarBackground: $pictonBlue;
$topBarSearchInput: $lighter;
$topBarSearchInputTextColor: $charcoal;
$topBarSearchInputPlaceholder: $darker;


/*******************************
** TABLE
*******************************/
$oddRowBackground: $lightPeriwinkle;
$oddRowForeground: $charcoal;
$evenRowBackground: $inchworm;
$evenRowForeground: $charcoal;


/*******************************
** GENERAL
*******************************/
$pageTitle: $outerSpace;
$accentPrimary: $pictonBlue;
$accentSecondary: $magicPotion;
$accentTertiary: $royalOrange;
$disabledButtonForeground: $lightPeriwinkle;
$disabledButtonBackground: $romanSilver;
$bodyBackground: $cultured;
$textSelectionColor: $moonstoneBlue;
$formBackground: $white;
$textInputBackground: $antiFlashWhite;
$textHighlight: $highlighterYellow;
$link: $newCar;
$separator: $brightGray;
$menuHoverBackground: $brightGray;